<template>
    <div class="edu-privateCloud">
         <div style="position:relative">
            <img class="bannerImg" src="../assets/images/privateCloud/banner.png" alt="">
            <div class="banner">
                <p class="title">{{pages.title}}</p>
                <p>{{pages.subtitle}}</p>
            </div>
        </div>
        <div class="paragraphs">
            <div class="types">
                <div v-for="(item,index) of pages.types" :key="index"
                :style="{'backgroundImage': `url(${item.url})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover','height':'2rem'}"
                >
                <img :src="item.icon" alt="" >
                <p class="subtitle">{{item.subtitle}}</p>
                </div>
            </div>
        </div>
        <div class="paragraphs unique">
            <div  class="product"  v-for="(item,index) of pages.productList.types" :key="index">
                <div class="left" 
                    style="background-color:#0061e3;opacity:0.2"
                    :style="{'backgroundImage': `url(${isActive==index?item.url:''})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover','height':'3.6rem'}">
                        <img :src="pages.productList.url" alt="" style="vertical-align:middle;margin-right:0.1rem">
                        <span class="title" >AI私有云</span>
                        <div class="subtitle" 
                        v-for="(it,i) of pages.productList.types" :key="i"
                        @mouseenter="isActive=i"
                        :class="isActive==i?'active':''"
                        >
                        <p class="guide">{{it.title}} <span v-if="isActive==i">→</span></p>
                        </div> 
                </div>
                 <div v-if="isActive==index" class="right content">
                    {{item.content}}
                </div>  
            </div>
          
        </div>
        <div class="paragraphs">
            <div>
                <table>
                    <thead>
                        <tr >
                            <th style="width:20%">类型</th>
                            <th style="width:30%">设备型号</th>
                            <th style="width:30%">外形</th>
                            <th style="width:20%" >用途</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) of pages.introduction" :key="index">
                            <td  :rowspan="index==0?3:0" v-if="index==0" style="text-align:center">{{item.type}}</td>
                            <td>
                              <p style="color:#0061E3">{{item.model.title}} </p>
                              <p>{{item.model.discription}}</p>
                            </td>
                            <td class="shape">
                                <div class="left">
                                    <p>{{item.shape.width}}</p>
                                    <p>{{item.shape.height}}</p>
                                    <p>{{item.shape.depth}}</p>
                                </div>
                                <div class="right">
                                    <img :src="item.shape.url" alt="">
                                </div>
                            </td>
                            <td :rowspan="index==0?3:0" v-if="index==0">{{item.use}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import newsData from '@/assets/js/news.js'
export default {
    name: 'eduHardware',
    data () {
        return {
            isActive: 0,
            pages: newsData.privateCloudList,
            
        }
    },
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    methods: {
      
    }
}
</script>
<style lang="scss" scoped>
.edu-privateCloud {
 .banner{
     left:10%;
     margin-right: 45%;
      p:nth-child(1){
         display: inline-block;
         line-height: 0.5rem;
         border-bottom: 1px solid #DDE9F9;   
        }
       p:nth-child(2){
       display: block;
       border-bottom: none;
       }
 }
    .paragraphs{
           .title{
               font-size: 0.3rem;
               font-weight: 400;
               color: #010101;
           }
           .subtitle{
               font-weight: 400;
               color: #FFFFFF;
               font-size: 0.2rem;
               margin-top: 0.2rem;
           }
           .types{
               display: flex;
               justify-content: space-between;
               >div{
                   width: 30%;
                   border-radius: 0.05rem;
                   padding: 0.5rem;
                   box-sizing: border-box;
                   display: flex;
                   flex-direction: column;
                   align-items: center;
               }
           }
           table {
               border-collapse: collapse;
               thead{
                   background: #D3DBE0;
                   text-align: center;
                   color:#333;
                   font-size: 0.18rem;
                   th{
                    border:1px solid #808080 ;
                    padding: 0.1rem;
                 }
               }
              td{
                    font-size: 0.16rem;
                    font-weight: 400;
                    line-height: 0.35rem;
                    color: #808080;
                    border:1px solid #808080 ;
                    height: 1.8rem;
                    padding: 0.2rem;
                    box-sizing: border-box;
               }
              
           }
           .shape{
               display: flex;
               justify-content: space-between;
               align-items: center;
               border: none;
               border-bottom:1px solid #808080  ;
               >div{
                   width: 45%;
                //    height: 3.5rem;
                   img{
                       width: 80%;
                   }
               }
           }
    }
    .unique{
        background-color: #F5F5F5;
        height: 4.5rem;
        position: relative;
        margin-top: 1rem;
          .product{
               display: flex;
               justify-content: space-between;
               width: 85%;
               height: 3.5rem;
               position: absolute;
               top:0;
               .left{
                   width: 42%;
                   height: 3.6rem;
                   position: absolute;
                   top:-1rem;
                   padding-left:20%;
                   padding-top: 0.3rem;
                   box-sizing: border-box;
                //    left:0;
                .title{
                    color: #FFFFFF
                }
                .subtitle{
                     color: #AEC8EC;
                     margin-top: 0.16rem;
                }
                .subtitle:hover{
                    cursor: pointer;
                }
                .active{
                    color:#FFFFFF;
                }
                .guide{
                    display: flex;
                    justify-content: space-between;
                    margin-right: 0.5rem;
                }
               }
               .right{
                   width: 58%;
                   position: absolute;
                   padding-left: 5%;
                   box-sizing: border-box;
                   top:0;
                   left: 42%;
               }
               .content{
                    font-size: 0.18rem;
                    font-weight: 400;
                    line-height: 0.35rem;
                    color: #808080;
                    text-indent: 2em;
               }
           }
    }
}

</style>
